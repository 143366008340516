/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h2: "h2",
    ol: "ol",
    li: "li",
    a: "a",
    em: "em"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Skyhawk Wins 2024 NMMA Innovation Award at Miami International Boat Show"), "\n", React.createElement(_components.p, null, "Skyhawk, a developer of IoT marine technology, has earned an award for innovation in marine consumer electronics. The award was presented at the 2024 Miami International Boat Show. Skyhawk received the 2024 NMMA Innovation Award in the category of mobile applications and consumer marine electronics. The Innovation Award recognizes the most innovative and cutting-edge products in the marine industry, and Skyhawk stood out among the competitors in this category."), "\n", React.createElement(_components.h2, null, "Relevant Links for More Information"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.nhbr.com/skyhawk-earns-award-for-innovation-in-marine-consumer-electronics/"
  }, "Skyhawk earns award for innovation in marine consumer electronics - New Hampshire Business Review")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://oversea.boats/skyhawk-oversea-wins-innovation-award-at-miami-international-boat-show-2024/"
  }, "Skyhawk Oversea™ Wins Innovation Award at Miami International Boat Show 2024")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.nmma.org/press/article/24619"
  }, "Innovation Award Winners Honored Today During the Discover Boating Industry Breakfast")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://boatingindustry.com/lead-news/2024/02/15/miami-industry-breakfast-honors-award-winners/"
  }, "Miami Industry Breakfast honors award winners")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.ibinews.com/awards/miami-2024-a-round-up-of-this-years-innovation-award-winners/47543.article"
  }, "Miami 2024: A round-up of this year's Innovation Award winners")), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "Please note that the information provided is based on the available sources and may be subject to change or further updates.")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
